import React from 'react';
import { SessionTime } from '../SessionTime';
import { EventTime } from '../EventTime';
import { getUnixTime, DATE_WITH_TIME_ZONE_FORMAT } from '@gf/cross-platform-lib/utils';

export interface EventTicketDateTimeProps {
  isAllDayEvent: boolean;
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
  isSameDay: boolean;
  isMobile: boolean;
}

export const EventTicketDateTime = ({
  isAllDayEvent,
  startDateTime,
  endDateTime,
  timeZone,
  isSameDay,
  isMobile
}: EventTicketDateTimeProps) => {
  return !isSameDay ? (
    <EventTime
      startDate={getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT)}
      endDate={getUnixTime(endDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT)}
      isMobile={isMobile}
    />
  ) : (
    <SessionTime
      inputDate={getUnixTime(startDateTime, timeZone, DATE_WITH_TIME_ZONE_FORMAT)}
      isSameDay={isSameDay}
      isAllDayEvent={isAllDayEvent}
      isMobile={isMobile}
    />
  );
};
