import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventFooterItem.styles';
import { Text, View } from 'react-native';
import { Carbonicons, IconName } from '@gf/cross-platform-lib/components/Icons';
import { eventCardComponent, testProperties } from '@gf/cross-platform-lib/utils';

const IconWrapper = styled(View)`
  ${styles.iconWrapper};
`;

const Wrapper = styled(View)<{ width: string }>`
  ${styles.wrapper};
`;

const TitleText = styled(Text)`
  ${styles.titleText};
`;

export interface EventFooterItemProps {
  iconName: IconName;
  title: string;
  iconColor?: string;
  isRotated?: boolean;
  width?: string;
}

export function EventFooterItem({
  iconName,
  title,
  iconColor,
  isRotated = false,
  width = 'auto'
}: EventFooterItemProps) {
  return (
    <Wrapper width={width}>
      <IconWrapper isRotated={isRotated}>
        <Carbonicons color={iconColor || '#000000'} name={iconName} size={16} />
      </IconWrapper>
      <TitleText {...testProperties(eventCardComponent.moreInfo)}>{title}</TitleText>
    </Wrapper>
  );
}
