import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css`
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  weekDayText: css`
    color: #323232;
    font-family: Rubik-SemiBold;
    font-size: 32px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    text-align: center;
  `,
  monthInYearText: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
  `,
  yearText: css`
    font-size: 16px;
    font-family: Rubik-Regular;
    color: black;
    line-height: 24px;
  `,
  timeText: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
  `
};
