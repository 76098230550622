import { css } from '@gf/cross-platform-lib/styled-components';

const dateFontSize = '18px';

export const baseStyles = {
  wrapper: css`
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  startDate: css`
    font-size: ${dateFontSize};
    font-family: Rubik-SemiBold;
    font-weight: 600;
    color: black;
    font-transform: capitalize;
    line-height: 27px;
  `,

  endDate: css`
    font-size: ${dateFontSize};
    font-family: Rubik-Regular;
    color: black;
    font-transform: capitalize;
    line-height: 27px;
  `,

  year: css`
    font-size: ${dateFontSize};
    font-family: Rubik-Regular;
    color: black;
    line-height: 27px;
  `,

  horizontalSeparator: css`
    width: 60px;
    height: 2px;
    text-align: center;
    background-color: rgba(50, 50, 50, 0.1);
    margin: 4px 0px;
  `,
  multiDateText: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
  `,
  date: css`
    color: #323232;
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 24px;
  `
};
