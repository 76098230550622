import { css, CSSObject, FlattenSimpleInterpolation } from 'styled-components';

type SizeLabelType = 'desktop' | 'tablet' | 'phone';

export const sizes: { desktop: number; tablet: number; phone: number } = {
  desktop: 768,
  tablet: 768,
  phone: 520
};

const createMediaTemplate = (
  acc: { [x: string]: (args_0: CSSObject) => FlattenSimpleInterpolation },
  label: SizeLabelType,
  typeOfLimit: string
) => {
  acc[label] = (...args: [CSSObject]) => css`
    @media (${typeOfLimit}: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
};

// Iterate through the sizes and create a media template
export const mediaQuery: any = Object.keys(sizes).reduce((acc, label) => {
  switch (label) {
    case 'tablet':
    case 'phone':
      return createMediaTemplate(acc, label, 'max-width');
    default:
      return createMediaTemplate(acc, label as SizeLabelType, 'min-width');
  }
}, {});
