import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';

import { styles } from './EventTime.styles';
import { Dayjs } from 'dayjs';
import { eventCardComponent, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const Date = styled.Text`
  ${styles.date};
`;

const MultiDateText = styled.Text`
  ${styles.multiDateText};
`;

const HorizontalSeparator = styled.View`
  ${styles.horizontalSeparator};
`;

interface EventTimeProps {
  startDate: Dayjs;
  endDate: Dayjs;
  isMobile: boolean;
}

export const EventTime = ({ startDate, endDate, isMobile }: EventTimeProps) => {
  const startEventDate = startDate.format('MMM D');
  const endEventDate = endDate.format('MMM D');

  return (
    <Wrapper isMobile={isMobile}>
      <Date {...testProperties(eventCardComponent.startDate)}>{startEventDate}</Date>
      <HorizontalSeparator />
      <Date {...testProperties(eventCardComponent.endDate)}>{endEventDate}</Date>
      <MultiDateText>Multiday</MultiDateText>
    </Wrapper>
  );
};
