import * as React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import dayjs, { Dayjs } from 'dayjs';

import { styles } from './SessionTime.styles';
import { Text, View } from 'react-native';
import { eventCardComponent, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const DayInWeekText = styled(Text)`
  ${styles.weekDayText};
`;

const MonthInYearText = styled(Text)`
  ${styles.monthInYearText};
`;

const YearText = styled(Text)`
  ${styles.yearText};
`;

const TimeText = styled(Text)`
  ${styles.timeText};
`;
interface SessionTimeProps {
  inputDate: Dayjs;
  isSameDay: boolean;
  isAllDayEvent: boolean;
  isMobile: boolean;
}
export const SessionTime = ({ inputDate, isSameDay, isAllDayEvent, isMobile }: SessionTimeProps) => {
  const dayInWeek = inputDate.format('ddd');
  const dayInMonth = Number.parseInt(inputDate.format('DD'), 10);
  const monthInYear = inputDate.format('MMM');
  const time = inputDate.format('h:mm A');
  const year = Number.parseInt(inputDate.format('YYYY'), 10);
  const isSameYear = year === dayjs().year();

  return (
    <Wrapper isMobile={isMobile}>
      <DayInWeekText {...testProperties(eventCardComponent.days)}>{dayInWeek}</DayInWeekText>
      <MonthInYearText {...testProperties(eventCardComponent.monthDay)}>
        {monthInYear} {dayInMonth}
      </MonthInYearText>
      {!isSameYear && <YearText {...testProperties(eventCardComponent.year)}>{year}</YearText>}
      <TimeText {...testProperties(eventCardComponent.time)}>{isSameDay && isAllDayEvent ? 'All day' : time}</TimeText>
    </Wrapper>
  );
};
