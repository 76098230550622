import { useMemo } from 'react';
import { Event, EventSeason, Season } from '@gf/cross-platform-lib/interfaces';
import {
  DATE_FORMAT,
  isFeaturedEvent,
  isTodayEvent,
  isUpcomingEvent,
  mapToEventSeason
} from '@gf/cross-platform-lib/utils';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import dayjs from 'dayjs';

interface PastAndUpcomingEventsResults {
  todayEvents: EventSeason[];
  upcomingEvents: EventSeason[];
}

const sortEventsByStartDate = (eventSeasons: EventSeason[], desc?: boolean): EventSeason[] =>
  [...eventSeasons].sort((prevEvent, postEvent) => {
    const preStartDate = dayjs(prevEvent.startDateTime, DATE_FORMAT);
    const postStartDate = dayjs(postEvent.startDateTime, DATE_FORMAT);
    const sortValue = desc ? -1 : 1;
    return postStartDate.isBefore(preStartDate) ? 1 * sortValue : -1 * sortValue;
  });

export const sortEvents = (eventSeasons: EventSeason[], schoolId = ''): EventSeason[] => {
  const featuredEvents = sortEventsByStartDate(
    eventSeasons.filter(
      eventSeason =>
        eventSeason && !eventSeason.isSeason && isFeaturedEvent(eventSeason.schoolsFeaturedOn || [], schoolId)
    )
  );
  const nonFeaturedEvents = sortEventsByStartDate(
    eventSeasons.filter(
      eventSeason =>
        eventSeason && !eventSeason.isSeason && !isFeaturedEvent(eventSeason.schoolsFeaturedOn || [], schoolId)
    )
  );
  const sortedSeasons = sortEventsByStartDate(eventSeasons.filter(eventSeason => eventSeason && eventSeason.isSeason));
  return sortedSeasons.concat(featuredEvents).concat(nonFeaturedEvents);
};

export const useGetTodayAndUpcomingEvents = (
  events: Event[] | undefined,
  seasons: Season[] | undefined
): PastAndUpcomingEventsResults => {
  return useMemo(() => {
    const eventOrSeason = events
      ? events.map(eItem => mapToEventSeason(eItem))
      : seasons
      ? seasons.map(sItem => mapToEventSeason(undefined, sItem))
      : [];
    const todayEventsLastIndex = findLastIndex(eventOrSeason, event =>
      isTodayEvent(event.startDateTime, event.endDateTime, event.timeZone)
    );
    const todayEventsIndex = findIndex(eventOrSeason, event =>
      isTodayEvent(event.startDateTime, event.endDateTime, event.timeZone)
    );

    const todayEvents =
      todayEventsIndex !== -1 ? [...eventOrSeason].slice(todayEventsIndex, todayEventsLastIndex + 1) : [];
    if (todayEventsIndex !== -1) {
      eventOrSeason.splice(todayEventsIndex, todayEvents.length);
    }

    return {
      todayEvents,
      upcomingEvents: eventOrSeason
    };
  }, [JSON.stringify(events), JSON.stringify(seasons)]);
};

interface UseUpcomingandPastEventsResults {
  pastEvents: EventSeason[];
  upcomingEvents: EventSeason[];
}

export const useUpcomingandPastEvents = (
  eventSeasons: EventSeason[],
  schoolId: string
): UseUpcomingandPastEventsResults => {
  return useMemo(() => {
    const sortedEvents = sortEvents(eventSeasons, schoolId);
    const pastEvents = sortedEvents.filter(
      event =>
        !isTodayEvent(event.startDateTime, event.endDateTime, event.timeZone) &&
        !isUpcomingEvent(event.startDateTime, event.endDateTime, event.timeZone)
    );
    const upcomingEvents = sortedEvents.filter(
      event =>
        isTodayEvent(event.startDateTime, event.endDateTime, event.timeZone) ||
        isUpcomingEvent(event.startDateTime, event.endDateTime, event.timeZone)
    );
    return {
      pastEvents: pastEvents.reverse(),
      upcomingEvents
    };
  }, [eventSeasons, schoolId]);
};
