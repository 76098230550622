import { css } from '@gf/cross-platform-lib/styled-components';
import { isEmpty } from '@gf/cross-platform-lib/utils';

export const baseStyles = {
  iconWrapper: css<{ isRotated: boolean }>`
    margin-right: 4px;
    ${({ isRotated }) => (isRotated ? 'transform: rotate(90deg);' : '')};
    width: 16px;
    height: 16px;
  `,
  titleText: css`
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    padding-right: 16px;
    line-height: 20px;
  `,
  wrapper: css<{ width: string }>`
    display: flex;
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 4px;
    ${({ width }) => (!isEmpty(width) ? `width: ${width};` : '')};
  `
};
