import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  iconWrapper: css<{ isRotated: boolean }>`
    ${baseStyles.iconWrapper}
  `,
  titleText: css`
    ${baseStyles.titleText}
  `,
  wrapper: css<{ width: string }>`
    ${baseStyles.wrapper}
  `
};
