import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  ...baseStyles,
  wrapper: css<{ isMobile: boolean }>`
    ${baseStyles.wrapper};
    display: inline-flex;
    margin-right: ${({ isMobile }) => (isMobile ? '0px' : '32px')};
  `
};
