import React from 'react';
import { EventSeasonCombined, IPagination, ISearchEventFilterParams } from '@gf/cross-platform-lib/interfaces';
import { useSearchEventsQuery, useSearchSeasonsQuery } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { DEFAULT_PAGINATION_REQUEST } from '@gf/cross-platform-lib/utils/pagination';
import { DEFAULT_PAGING_SIZE, DEFAULT_SHOW_MORE_SIZE } from '@gf/cross-platform-lib/constants';
import { useGetTodayAndUpcomingEvents } from '@gf/cross-platform-lib/hooks';
import { isFeaturedEvent } from '@gf/cross-platform-lib/utils';

const paginate = (items: any[], page = 1, perPage = DEFAULT_PAGINATION_REQUEST.size) => {
  const offset = perPage * (page - 1);
  return items.slice(offset, perPage * page);
};

const filterByFeatured = (events: EventSeasonCombined[], schoolId = '') => {
  let featured: EventSeasonCombined[] = [];
  let nonFeatured: EventSeasonCombined[] = [];
  events.forEach(event => {
    if (isFeaturedEvent(event.schoolsFeaturedOn || [], schoolId)) {
      featured.push(event);
    } else {
      nonFeatured.push(event);
    }
  });
  return [featured, nonFeatured] as const;
};

export const useSearchSchoolEvents = (
  filters: ISearchEventFilterParams,
  initialPagination: IPagination = DEFAULT_PAGINATION_REQUEST
) => {
  const [page, setPage] = React.useState(initialPagination.page || 1);
  const [eventFilters, setFilters] = React.useState(filters);
  const seasonQuery = useSearchSeasonsQuery(eventFilters, initialPagination);
  const eventQuery = useSearchEventsQuery(eventFilters, initialPagination);

  const { todayEvents: todaySeasons, upcomingEvents: upComingSeasons } = useGetTodayAndUpcomingEvents(
    undefined,
    seasonQuery?.items
  );
  const { todayEvents: todayEventsList, upcomingEvents: upComingEventsList } = useGetTodayAndUpcomingEvents(
    eventQuery.items,
    undefined
  );
  const [todayFeaturedEvents, todayNoneFeaturedEvents] = filterByFeatured(todayEventsList, filters.schoolId);
  const [upComingFeaturedEvents, upComingNoneFeaturedEvents] = filterByFeatured(upComingEventsList, filters.schoolId);
  const allEventSeasons = [
    ...todaySeasons,
    ...todayFeaturedEvents,
    ...todayNoneFeaturedEvents,
    ...upComingSeasons,
    ...upComingFeaturedEvents,
    ...upComingNoneFeaturedEvents
  ];

  const totalItems = (seasonQuery?.lastPage?.totalElements || 0) + (eventQuery?.lastPage?.totalElements || 0);
  const currentPage = Math.max(seasonQuery?.lastPage?.number, eventQuery.lastPage?.number) || 0;
  const paginateEventSeasons = React.useMemo(() => {
    return paginate(allEventSeasons, 1, DEFAULT_SHOW_MORE_SIZE * page + (DEFAULT_PAGING_SIZE - DEFAULT_SHOW_MORE_SIZE));
  }, [allEventSeasons, currentPage]);

  const allTodayEventsLength = todaySeasons.length + todayFeaturedEvents.length + todayNoneFeaturedEvents.length || 0;
  const todayEvents = allTodayEventsLength > 0 ? paginateEventSeasons.slice(0, allTodayEventsLength) : [];

  const upcomingEvents =
    allTodayEventsLength > 0 ? paginateEventSeasons.slice(allTodayEventsLength) : paginateEventSeasons;
  return {
    todayEvents,
    upcomingEvents,
    items: paginateEventSeasons,
    totalItems: totalItems,
    totalRemainingItems: totalItems - paginateEventSeasons.length,
    isFetching: seasonQuery?.isFetching || eventQuery.isFetching,
    isLoading: seasonQuery?.isLoading || eventQuery.isLoading,
    isFetchingNextPage:
      paginateEventSeasons.length === allEventSeasons.length &&
      (seasonQuery?.isFetchingNextPage || eventQuery.isFetchingNextPage),
    refetch: async () => {
      await Promise.all([seasonQuery?.refetch(), eventQuery.refetch()]);
      setPage(1);
    },
    loadMore: () => {
      seasonQuery?.loadMore();
      eventQuery.loadMore();
      setPage(prvPage => prvPage + 1);
    },
    applyFilter: (filters: ISearchEventFilterParams) => setFilters(filters)
  };
};
